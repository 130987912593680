import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { Helmet } from "react-helmet-async";

const PageNotFound = () => {
  const navigate = useNavigate();

  const onLoginBackToHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <div className="flex flex-col w-full h-[calc(100vh-2px)] items-center justify-center bg-white text-left text-21xl text-offers-green font-h2 font-sofia-pro box-border">
        <div className="sm:hidden flex flex-col p-10 w-[361] z-[10] rounded-xl bg-white shadow-[0px_3px_9px_rgba(0,_0,_0,_0.25)]  items-center justify-center gap-[19px]">
          <img
            src={process.env.REACT_APP_LOGO}
            alt="ELTic"
            className=" cursor-pointer w-[230px] p-5 shrink-0"
            onClick={() => navigate("/")}
          />

          <div className="flex flex-col items-center justify-center gap-[10px] p-[10px]">
            <div className="flex flex-row w-full items-center justify-center gap-[2px] tracking-[0.01em] font-semibold font-sofia-pro text-center text-[36px] text-highlight">
              Page not found
            </div>
          </div>
          <div
            onClick={onLoginBackToHomeClick}
            className="w-[361px] flex flex-col p-2.5 box-border items-start justify-start text-center text-base text-white cursor-pointer"
          >
            <div className="self-stretch rounded-[47px] bg-button_primary h-[47px] shrink-0 flex flex-row py-0 px-[39px] box-border items-center justify-center gap-[10px]">
              <div className="relative leading-[29px] font-semibold">
                Back to ELTic - Order Action List
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:flex sm:flex-col p-[10px_0px_0px_0px] w-[95%] z-[10] rounded-xl bg-white shadow-[0px_3px_9px_rgba(0,_0,_0,_0.25)]  items-center justify-center gap-[19px]">
          <div className="flex flex-col w-[95%] items-center justify-center gap-[10px]">
            <div className="flex flex-row w-full items-center justify-center gap-[2px] relative tracking-[0.01em] font-semibold font-sofia-pro text-center text-[32px] text-highlight">
              Page not found
            </div>
          </div>
          <div
            onClick={onLoginBackToHomeClick}
            className="flex flex-col w-full p-[10px] box-border items-start justify-start text-center text-base text-white cursor-pointer"
          >
            <div className="self-stretch rounded-[47px] bg-button_primary h-[47px] flex flex-row py-0 px-[5px] box-border items-center justify-center gap-[10px]">
              <div className="relative leading-[29px] font-semibold">
                Back to ELTic - Order Action List
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
