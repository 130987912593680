import React from "react";
import "./App.css";
import RouteList from "./pages/routes";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const App = () => {
  const helmetContext = {};
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <title>ELTic - Jobs</title>
        </div>

        <RouteList />
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.");
}
