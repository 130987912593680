import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const onLoginBackToHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="flex flex-col w-full h-[calc(100vh-2px)] items-center justify-center bg-white text-left font-sofia-pro">
        <div className="flex flex-col p-10 w-[95%] h-[95%] bg-white items-center justify-center gap-[10px] ">
          <div className=" flex flex-row gap-[2px] box-border w-full">
            <div className="flex w-[80%]  text-8xl text-left  font-semibold font-sofia-pro text-highlight cursor-pointer">
              Privacy Policy
            </div>
            <div className="w-[20%] justify-right text-right">
              <button
                className=" h-[40px] rounded-[40px] text-center font-semibold  box-border border border-solid border-button_primary relative w-[60px] p-[0px_10px] leading-[28px] inline-block text-normal capitalize cursor-pointer"
                onClick={onLoginBackToHomeClick}
              >
                X
              </button>
              &nbsp;
            </div>
          </div>
          <div className="flex flex-col box-border w-[98%] overflow-auto text-1xl">
            <div className="inline-block text-primary-slate text-[16px] ">
              <p>
                <span className="font-bold">
                  What is the purpose of our GDPR privacy policy?
                </span>
              </p>
              <p>
                <span className="font-bold">ELTic Limited</span>
                <span>
                  &nbsp;is committed to protecting the privacy and security of
                  your personal information. This privacy notice describes how
                  we collect and use personal information about you during and
                  after your working relationship with us, in accordance with
                  the General Data Protection Regulation (GDPR). It applies to
                  all employees, workers and contractors.
                </span>
              </p>
              <p>
                <span>
                  ELTic is a &ldquo;data controller&rdquo;. This means that we
                  are responsible for deciding how we hold and use personal
                  information about you. We are required under data protection
                  legislation to notify you of the information contained in this
                  privacy notice.
                </span>
              </p>
              <p>
                <span>
                  This notice applies to current and former employees, workers
                  and contractors. This notice does not form part of any
                  contract of employment or other contract to provide services.
                  We may update this notice at any time. It is important that
                  you read this notice, together with any other privacy notice
                  we may provide on specific occasions when we are collecting or
                  processing personal information about you, so that you are
                  aware of how and why we are using such information.
                </span>
              </p>
              <p>
                <span className="font-bold">Data Protection principles</span>
              </p>
              <p>
                <span>
                  We will comply with data protection law. This says that the
                  personal information we hold about you must be:
                </span>
              </p>
              <ol start="1">
                <li>
                  <span>Used lawfully, fairly and in a transparent way.</span>
                </li>
                <li>
                  <span>
                    Collected only for valid purposes that we have clearly
                    explained to you and not used in any way that is
                    incompatible with those purposes.
                  </span>
                </li>
                <li>
                  <span>
                    Relevant to the purposes we have told you about and limited
                    only to those purposes.
                  </span>
                </li>
                <li>
                  <span>Accurate and kept up to date.</span>
                </li>
                <li>
                  <span>
                    Kept only as long as necessary for the purposes we have told
                    you about.
                  </span>
                </li>
                <li>
                  <span>Kept securely.</span>
                </li>
              </ol>
              <p>
                <span className="font-bold">
                  The kind of information we hold about you
                </span>
              </p>
              <p>
                <span>
                  Personal data, or personal information, means any information
                  about an individual from which that person can be identified.
                  It does not include data where the identity has been removed
                  (anonymous data). There are &ldquo;special categories&rdquo;
                  of more sensitive personal data which require a higher level
                  of protection. We will collect, store, and use the following
                  categories of personal information about you:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    Personal contact details such as name, title, telephone
                    numbers, and personal email addresses.
                  </span>
                </li>
                <li>
                  <span>Public Legal Information.</span>
                </li>
                <li>
                  <span>Location of employment or workplace.</span>
                </li>
                <li>
                  <span>
                    Recruitment information (including copies of right to work
                    documentation, references and other information included in
                    a CV or cover letter or as part of the application process).
                  </span>
                </li>
                <li>
                  <span>
                    Employment records (including job titles, work history,
                    certification, accreditation and professional memberships).
                  </span>
                </li>
                <li>
                  <span>Skills and recommendations.</span>
                </li>
                <li>
                  <span>
                    Information about your use of our information and
                    communications systems.
                  </span>
                </li>
                <li>
                  <span>Photographs.</span>
                </li>
              </ul>
              <p>
                <span></span>
              </p>
              <p>
                <span className="font-bold">
                  How is your personal information collected?
                </span>
              </p>
              <p>
                <span>
                  We collect personal information about legal and contactors
                  through the application and recruitment process, either
                  directly from candidates or sometimes from an employment
                  agency or data provider.{" "}
                </span>
              </p>
              <p>
                <span className="font-bold">
                  How will we use information about you?
                </span>
              </p>
              <p>
                <span>
                  We will only use your personal information when the law allows
                  us to. Most commonly, we will use your personal information in
                  the following circumstances:
                </span>
              </p>
              <ol start="1">
                <li>
                  <span>Where we need to comply with a legal obligation.</span>
                </li>
                <li>
                  <span>
                    Where it is necessary for our legitimate interests (or those
                    of a third party) and your interests and fundamental rights
                    do not override those interests.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  We may also use your personal information in the following
                  situations, which are likely to be rare:
                </span>
              </p>
              <ol start="1">
                <li>
                  <span>
                    Where we need to protect your interests (or someone
                    else&rsquo;s interests).
                  </span>
                </li>
                <li>
                  <span>
                    Where it is needed in the public interest or for official
                    purposes.
                  </span>
                </li>
              </ol>
              <p>
                <span className="font-bold">
                  Situations in which we will use your personal information
                </span>
              </p>
              <p>
                <span>
                  We need all the categories of information in the list above
                  primarily to allow us to perform our service with you and to
                  enable us to comply with legal obligations. In some cases we
                  may use your personal information to pursue legitimate
                  interests of our own or those of third parties, provided your
                  interests and fundamental rights do not override those
                  interests. The situations in which we will process your
                  personal information are listed below.
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    Making a decision about your suitability for recruitment or
                    appointment.
                  </span>
                </li>
                <li>
                  <span>Determining the terms on which you work.</span>
                </li>
                <li>
                  <span>
                    Checking you are legally entitled to work in the UK/Ireland.
                  </span>
                </li>
                <li>
                  <span>Assessing qualifications for a particular job.</span>
                </li>
                <li>
                  <span>Education, training and development requirements.</span>
                </li>
                <li>
                  <span>Dealing with legal disputes involving you.</span>
                </li>
                <li>
                  <span>Ascertaining your fitness to work.</span>
                </li>
                <li>
                  <span>To prevent fraud.</span>
                </li>
                <li>
                  <span>
                    To monitor your use of our information and communication
                    systems to ensure compliance with our IT policies.
                  </span>
                </li>
                <li>
                  <span>
                    To ensure network and information security, including
                    preventing unauthorised access to our computer and
                    electronic communications systems and preventing malicious
                    software distribution.
                  </span>
                </li>
                <li>
                  <span>
                    To conduct data analytics studies to review and better
                    understand candidate retention and attrition rates.
                  </span>
                </li>
                <li>
                  <span>Equal opportunities monitoring.</span>
                </li>
              </ul>
              <p>
                <span>
                  Some of the above grounds for processing will overlap and
                  there may be several grounds which justify our use of your
                  personal information.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  If you fail to provide personal information
                </span>
              </p>
              <p>
                <span>
                  If you fail to provide certain information when requested, we
                  may not be able to perform the service , or we may be
                  prevented from complying with our legal obligations.
                </span>
              </p>
              <p>
                <span className="font-bold">Change of purpose</span>
              </p>
              <p>
                <span>
                  We will only use your personal information for the purposes
                  for which we collected it, unless we reasonably consider that
                  we need to use it for another reason and that reason is
                  compatible with the original purpose. If we need to use your
                  personal information for an unrelated purpose, we will notify
                  you and we will explain the legal basis which allows us to do
                  so. Please note that we may process your personal information
                  without your knowledge or consent, in compliance with the
                  above rules, where this is required or permitted by law.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  How we use particularly sensitive personal information
                </span>
              </p>
              <p>
                <span>
                  &ldquo;Special categories&rdquo; of particularly sensitive
                  personal information require higher levels of protection. We
                  need to have further justification for collecting, storing and
                  using this type of personal information. We have in place an
                  appropriate policy document and safeguards which we are
                  required by law to maintain when processing such data.
                </span>
              </p>
              <p>
                <span>
                  We may process special categories of personal information in
                  the following circumstances:
                </span>
              </p>
              <ol>
                <li>
                  <span>
                    In limited circumstances, with your explicit written
                    consent.
                  </span>
                </li>
                <li>
                  <span>
                    Where we need to carry out our legal obligations or exercise
                    rights in connection with employment.
                  </span>
                </li>
                <li>
                  <span>
                    Where it is needed in the public interest, such as for equal
                    opportunities monitoring or in relation to our occupational
                    pension scheme.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  Less commonly, we may process this type of information where
                  it is needed in relation to legal claims or where it is needed
                  to protect your interests (or someone else&rsquo;s interests)
                  and you are not capable of giving your consent, or where you
                  have already made the information public. We may also process
                  such information about members or former members in the course
                  of legitimate business activities with the appropriate
                  safeguards.
                </span>
              </p>
              <p>
                <span></span>
              </p>
              <p>
                <span className="font-bold">Do we need your consent?</span>
              </p>
              <p>
                <span>
                  We do not need your consent if we use special categories of
                  your personal information in accordance with our written
                  policy to carry out our legal obligations or exercise specific
                  rights in the field of employment law. In limited
                  circumstances, we may approach you for your written consent to
                  allow us to process certain particularly sensitive data. If we
                  do so, we will provide you with full details of the
                  information that we would like and the reason we need it, so
                  that you can carefully consider whether you wish to consent.
                  You should be aware that it is not a condition of our service
                  that you agree to any request for consent from us.
                </span>
              </p>
              <p>
                <span className="font-bold">Automated decision-making</span>
              </p>
              <p>
                <span>
                  Automated decision-making takes place when an electronic
                  system uses personal information to make a decision without
                  human intervention. We are allowed to use automated
                  decision-making in the following circumstances:
                </span>
              </p>
              <ol start="1">
                <li>
                  <span>
                    Determining if we can place you on a long-list for the
                    position advertised by a recruitment agency. &nbsp;Humans
                    will decide to place you on a short-list.
                  </span>
                </li>
                <li>
                  <span>
                    Where it is necessary to perform the service and appropriate
                    measures are in place to safeguard your rights.
                  </span>
                </li>
                <li>
                  <span>
                    In limited circumstances, with your explicit consent and
                    where appropriate measures are in place to safeguard your
                    rights.
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  If we make an automated decision on the basis of any
                  particularly sensitive personal information, we must have
                  either your explicit written consent or it must be justified
                  in the public interest, and we must also put in place
                  appropriate measures to safeguard your rights. You will not be
                  subject to decisions that will have a significant impact on
                  you based solely on automated decision-making, unless we have
                  a lawful basis for doing so and we have notified you.{" "}
                </span>
              </p>
              <p>
                <span className="font-bold">Data Sharing</span>
              </p>
              <p>
                <span>
                  We may have to share your data with third parties, including
                  third-party recruitment agencies and other entities in the
                  group. We require third parties to respect the security of
                  your data and to treat it in accordance with the law. We may
                  transfer your personal information outside the EU. If we do,
                  you can expect a similar degree of protection in respect of
                  your personal information.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  Why might you share personal information with third parties?
                </span>
              </p>
              <p>
                <span>
                  We will share your personal information with third parties
                  where required by law, where it is necessary to perform the
                  service or where we have another legitimate interest in doing
                  so.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  Which third-party service providers process my personal
                  information
                </span>
              </p>
              <p>
                <span>
                  &ldquo;Third parties&rdquo; includes third-party service
                  providers (including contractors and designated agents) and
                  other entities within our group. The following activities are
                  carried out by third-party service providers: [Administration,
                  IT services].
                </span>
              </p>
              <p>
                <span className="font-bold">
                  How secure is my information with third-party service
                  providers and other entities in our group?
                </span>
              </p>
              <p>
                <span>
                  All our third-party service providers and other entities in
                  the group are required to take appropriate security measures
                  to protect your personal information in line with our
                  policies. We do not allow our third-party service providers to
                  use your personal data for their own purposes. We only permit
                  them to process your personal data for specified purposes and
                  in accordance with our instructions.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  When might you share my personal information with other
                  entities in the group?
                </span>
              </p>
              <p>
                <span>
                  We will share your personal information with other entities in
                  our group as part of our regular reporting activities on
                  company performance, in the context of a business
                  reorganisation or group restructuring exercise, for system
                  maintenance support and hosting of data.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  What about other third parties?
                </span>
              </p>
              <p>
                <span>
                  We may share your personal information with other third
                  parties, for example in the context of the possible sale or
                  restructuring of the business. We may also need to share your
                  personal information with a regulator or to otherwise comply
                  with the law.
                </span>
              </p>
              <p>
                <span className="font-bold">Data security</span>
              </p>
              <p>
                <span>
                  We have put in place measures to protect the security of your
                  information. Details of these measures are available upon
                  request. Third parties will only process your personal
                  information on our instructions and where they have agreed to
                  treat the information confidentially and to keep it secure. We
                  have put in place appropriate security measures to prevent
                  your personal information from being accidentally lost, used
                  or accessed in an unauthorised way, altered or disclosed. In
                  addition, we limit access to your personal information to
                  those employees, agents, contractors and other third parties
                  who have a business need to know. They will only process your
                  personal information on our instructions and they are subject
                  to a duty of confidentiality. We have put in place procedures
                  to deal with any suspected data security breach and will
                  notify you and any applicable regulator of a suspected breach
                  where we are legally required to do so.
                </span>
              </p>
              <p>
                <span className="font-bold">Data retention</span>
              </p>
              <p>
                <span>
                  We will only retain your personal information for as long as
                  necessary to fulfil the purposes we collected it for,
                  including for the purposes of satisfying any legal or
                  reporting requirements. Details of retention periods for
                  different aspects of your personal information are available
                  in our retention policy which is available from the Compliance
                  Officer. To determine the appropriate retention period for
                  personal data, we consider the amount, nature, and sensitivity
                  of the personal data, the potential risk of harm from
                  unauthorised use or disclosure of your personal data, the
                  purposes for which we process your personal data and whether
                  we can achieve those purposes through other means, and the
                  applicable legal requirements.
                </span>
              </p>
              <p>
                <span>
                  In some circumstances we may anonymise your personal
                  information so that it can no longer be associated with you,
                  in which case we may use such information without further
                  notice to you. Once you have given us notice to remove your
                  information, we will retain and securely destroy your personal
                  information in accordance with our data retention policy.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  Rights of access, correction, erasure, and restriction
                </span>
              </p>
              <p>
                <span>
                  It is important that the personal information we hold about
                  you is accurate and current. Please keep us informed if your
                  personal information changes.
                </span>
              </p>
              <p>
                <span className="font-bold">
                  Your rights in connection with personal information
                </span>
              </p>
              <p>
                <span>
                  Under certain circumstances, by law you have the right to:
                </span>
              </p>
              <ul class="c5 lst-kix_nmz75hbzixr-0 start">
                <li>
                  <span>
                    Request access to your personal information (commonly known
                    as a &ldquo;data subject access request&rdquo;). This
                    enables you to receive a copy of the personal information we
                    hold about you and to check that we are lawfully processing
                    it.
                  </span>
                </li>
                <li>
                  <span>
                    Request correction of the personal information that we hold
                    about you. This enables you to have any incomplete or
                    inaccurate information we hold about you corrected.
                  </span>
                </li>
                <li>
                  <span>
                    Request erasure of your personal information. This enables
                    you to ask us to delete or remove personal information where
                    there is no good reason for us continuing to process it. You
                    also have the right to ask us to delete or remove your
                    personal information where you have exercised your right to
                    object to processing (see below).
                  </span>
                </li>
                <li>
                  <span>
                    Object to processing of your personal information where we
                    are relying on a legitimate interest (or those of a third
                    party) and there is something about your particular
                    situation which makes you want to object to processing on
                    this ground. You also have the right to object where we are
                    processing your personal information for direct marketing
                    purposes.
                  </span>
                </li>
                <li>
                  <span>
                    Request the restriction of processing of your personal
                    information. This enables you to ask us to suspend the
                    processing of personal information about you, for example if
                    you want us to establish its accuracy or the reason for
                    processing it.
                  </span>
                </li>
                <li>
                  <span>
                    Request the transfer of your personal information to another
                    party.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  If you want to review, verify, correct or request erasure of
                  your personal information, object to the processing of your
                  personal data, or request that we transfer a copy of your
                  personal information to another party, please contact the
                  Compliance Officer in writing.
                </span>
              </p>
              <p>
                <span className="font-bold">No fee usually required</span>
              </p>
              <p>
                <span>
                  You will not have to pay a fee to access your personal
                  information (or to exercise any of the other rights). However,
                  we may charge a reasonable fee if your request for access is
                  clearly unfounded or excessive. Alternatively, we may refuse
                  to comply with the request in such circumstances.
                </span>
              </p>
              <p>
                <span className="font-bold">What we may need from you</span>
              </p>
              <p>
                <span>
                  We may need to request specific information from you to help
                  us confirm your identity and ensure your right to access the
                  information (or to exercise any of your other rights). This is
                  another appropriate security measure to ensure that personal
                  information is not disclosed to any person who has no right to
                  receive it.
                </span>
              </p>
              <p>
                <span className="font-bold">Right to withdraw consent</span>
              </p>
              <p>
                <span>
                  In the limited circumstances where you may have provided your
                  consent to the collection, processing and transfer of your
                  personal information for a specific purpose, you have the
                  right to withdraw your consent for that specific processing at
                  any time. To withdraw your consent, please login to our
                  ELTic website and choose to cancel your consent. Once we
                  have received notification, we will no longer process your
                  information for the purpose or purposes you originally agreed
                  to, unless we have another legitimate basis for doing so in
                  law.
                </span>
              </p>
              <p>
                <span className="font-bold">Changes to the privacy notice</span>
              </p>
              <p>
                <span>
                  We reserve the right to update this privacy notice at any
                  time, and we will provide you with a new privacy notice when
                  we make any substantial updates. We may also notify you in
                  other ways from time to time about the processing of your
                  personal information.
                  <br />
                  If you have any questions about this privacy notice, please
                  read the latest verson on our website.
                </span>
              </p>
              <p>
                <span class="c6"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
