import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { Helmet } from 'react-helmet-async';

const Version = () => {
  const navigate = useNavigate();
  const date = "1 Feb 2024 11:59";

  const onLoginBackToHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Version</title>
      </Helmet>
      <div className="flex flex-col w-full h-[calc(100vh-2px)] items-center justify-center bg-white text-left text-21xl text-offers-green font-h2 font-sofia-pro box-border">
        <div className="w-[361px] flex flex-row items-start justify-center gap-[2px]">
          <div className="relative tracking-[0.01em] font-semibold font-sofia-pro text-[32px] text-highlight">
            Latest Version Release
          </div>
        </div>
        <div className="relative text-primary-slate pt-5 text-center inline-block w-[361px] text-[24px]">
          <p className="m-0">
            <span className="leading-[29px]">{date}</span>
            <br />
          </p>
        </div>

        <div
          onClick={onLoginBackToHomeClick}
          className="w-[361px] flex flex-col p-2.5 box-border items-start justify-start text-center text-base text-white cursor-pointer"
        >
          <div className="self-stretch rounded-[47px] bg-button_primary h-[47px] shrink-0 flex flex-row py-0 px-[39px] box-border items-center justify-center gap-[10px]">
            <div className="relative leading-[29px] font-semibold">
              Back to ELTic
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Version;
