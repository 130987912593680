import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { Helmet } from "react-helmet-async";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const onLoginBackToHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <div className="flex flex-col w-full h-[calc(100vh-2px)] items-center justify-center bg-white text-left font-sofia-pro">
        <div className="flex flex-col p-10 w-[95%] h-[95%] bg-white items-center justify-center gap-[10px] ">
          <div className=" flex flex-row gap-[2px] box-border w-full">
            <div className="flex w-[80%]  text-8xl text-left  font-semibold font-sofia-pro text-highlight cursor-pointer">
              Terms and Conditions
            </div>
            <div className="w-[20%] justify-right text-right">
              <button
                className="  rounded-[40px] h-[40px] text-center font-semibold  box-border border border-solid border-button_primary relative w-[60px] p-[0px_10px] leading-[28px] inline-block text-normal capitalize cursor-pointer"
                onClick={onLoginBackToHomeClick}
              >
                X
              </button>
              &nbsp;
            </div>
          </div>

          <div className="flex flex-col box-border w-[98%] overflow-auto text-1xl pt-3">
            <span className="">
              PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
              SITE
            </span>
            <p className="font-bold">
              <span>Terms of Website Use</span>
            </p>
            <p>
              <span className="">
                This site is operated by ELTic Limited.
                <br />
                These terms of use (together with the documents referred to in
                them) set out the rules governing your use of our website
                (&ldquo;our site&rdquo;), whether as a guest or a registered
                user. Use of our site includes accessing, browsing, or
                registering to use our site.
                <br />
                Please read these terms of use carefully before you start to use
                our site, as these will apply to your use of our site. By using
                our site, you confirm that you accept these terms of use and
                that you agree to comply with them. If you do not agree to these
                terms of use, you must not use our site.
              </span>
            </p>
            <p className="font-bold">
              <span>Privacy and Cookies</span>
            </p>
            <p>
              <span className="">
                These terms of use supplement (and are in addition to) the terms
                of our Privacy Policy and our Cookie Policy. Our Privacy Policy
                sets out the terms on which we process any personal data we
                collect from you or that you provide to us. By using our site,
                you consent to such processing and you warrant that all data
                provided by you is accurate. Our Cookie Policy sets out
                information about the cookies we use on our site.
              </span>
            </p>
            <p className="font-bold">
              <span>Other applicable terms</span>
            </p>
            <p>
              <span className="">
                If you purchase goods or services through our site, the relevant
                terms and conditions of supply for the relevant product or
                service will also apply. These will be clearly signposted on our
                site or during the registration process.
              </span>
            </p>
            <p className="font-bold">
              <span>Changes to these terms of use or to our site</span>
            </p>
            <p>
              <span className="">
                We may revise these terms of use at any time by amending this
                page. Please check this page from time to time to take notice of
                any changes we made, as they are binding on you. We may also
                update our site from time to time, and may change the content at
                any time. However, please note that any of the content on our
                site may be out of date at any given time, and we are under no
                obligation to update it. Some of the provisions contained in
                these terms of use may also be superseded by provisions or
                notices published elsewhere on our site. We do not guarantee
                that our site, or any content on it, will be free from errors or
                omissions.
              </span>
            </p>
            <p className="font-bold">
              <span>Accessing our site</span>
            </p>
            <p>
              <span className="">
                Our site is made available free of charge. We do not guarantee
                that our site, or any content on it, will always be available or
                be uninterrupted. Access to our site is permitted on a temporary
                basis. We may suspend, withdraw, discontinue or change all or
                any part of our site without notice. We will not be liable to
                you if for any reason our site is unavailable at any time or for
                any period. You are responsible for making all arrangements
                necessary for you to have access to our site. You are also
                responsible for ensuring that all persons who access our site
                through your internet connection are aware of these terms of use
                and other applicable terms and conditions, and that they comply
                with them.
              </span>
            </p>
            <p className="font-bold">
              <span>Acceptable use</span>
            </p>
            <ul>
              <li>
                <span className="">
                  You may use our site only for lawful purposes. You may not use
                  our site:
                </span>
              </li>
              <li>
                <span className="">
                  In any way that breaches any applicable local, national or
                  international law or regulation.
                </span>
              </li>
              <li>
                <span className="">
                  In any way that is unlawful or fraudulent, or has any unlawful
                  or fraudulent purpose or effect.
                </span>
              </li>
              <li>
                <span className="">
                  For the purpose of harming or attempting to harm minors in any
                  way.
                </span>
              </li>
              <li>
                <span className="">
                  To send, knowingly receive, upload, download, use or re-use
                  any material which does not comply with our content standards.
                </span>
              </li>
              <li>
                <span className="">
                  To transmit, or procure the sending of, any unsolicited or
                  unauthorised advertising or promotional material or any other
                  form of similar solicitation (spam).
                </span>
              </li>
              <li>
                <span className="">
                  To knowingly transmit any data, send or upload any material
                  that contains viruses, Trojan horses, worms, time-bombs,
                  keystroke loggers, spyware, adware or any other harmful
                  programs or similar computer code designed to adversely affect
                  the operation of any computer software or hardware.
                </span>
              </li>
            </ul>
            <p className="font-bold">
              <span>You also agree:</span>
            </p>
            <ul>
              <li>
                <span className="">
                  Not to reproduce, duplicate, copy or re-sell any part of our
                  site in contravention of these terms of use.
                </span>
              </li>
              <li>
                <span className="">
                  Not to access without authority, interfere with, damage or
                  disrupt any part of our site, any equipment or network on
                  which our site is stored, any software used in the provision
                  of our site or any equipment or network or software owned or
                  used by any third party.
                </span>
              </li>
            </ul>
            <p>
              <span className="">
                Interactive services &ndash; We may from time to time provide
                interactive services on our site, including, without limitation,
                chat rooms, bulletin boards and blogs (&ldquo;interactive
                services&rdquo;). Where we do provide any interactive service,
                we will provide clear information to you about the kind of
                service offered, if it is moderated and what form of moderation
                is used (including whether it is human or technical). Where we
                do moderate an interactive service, we will normally provide you
                with a means of contacting the moderator, should a concern or
                difficulty arise.
              </span>
            </p>
            <p>
              <span className="">
                We will do our best to assess any possible risks for users (and
                in particular, for children) from third parties when they use
                any interactive service provided on our site, and we will decide
                in each case whether it is appropriate to use moderation of the
                relevant service (including what kind of moderation to use) in
                the light of those risks. However, we are under no obligation to
                oversee, monitor or moderate any interactive service we provide
                on our site, and we expressly exclude our liability for any loss
                or damage arising from the use of any interactive service by a
                user in contravention of our content standards, whether the
                service is moderated or not.
              </span>
            </p>
            <p>
              <span className="">
                We will do our best to assess any possible risks for users (and
                in particular, for children) from third parties when they use
                any interactive service provided on our site, and we will decide
                in each case whether it is appropriate to use moderation of the
                relevant service (including what kind of moderation to use) in
                the light of those risks. However, we are under no obligation to
                oversee, monitor or moderate any interactive service we provide
                on our site, and we expressly exclude our liability for any loss
                or damage arising from the use of any interactive service by a
                user in contravention of our content standards, whether the
                service is moderated or not.
              </span>
            </p>
            <p className="font-bold">
              <span>Content Standards</span>
            </p>
            <p>
              <span className="">
                These content standards apply to any and all material which you
                contribute to our site ("contributions"), and to any interactive
                services associated with it. You must comply with the spirit and
                the letter of the following standards. The standards apply to
                each part of any contribution as well as to its whole. You
                warrant that any such contribution does comply with the
                standards listed below, and you will be liable to us and
                indemnify us for any breach of this warranty.
              </span>
            </p>
            <p>
              <span className="">
                Contributions must be accurate (where they state facts), be
                genuinely held (where they state opinions) and comply with
                applicable law in the UK and in any country from which they are
                posted.
              </span>
            </p>
            <p className="font-bold">
              <span>Contributions must not:</span>
            </p>
            <ul>
              <li>
                <span className="">
                  Contain any material which is defamatory of any person.
                </span>
              </li>
              <li>
                <span className="">
                  Contain any material which is obscene, offensive, hateful or
                  inflammatory.
                </span>
              </li>
              <li>
                <span className="">Promote sexually explicit material.</span>
              </li>
              <li>
                <span className="">Promote violence.</span>
              </li>
              <li>
                <span className="">
                  Promote discrimination based on race, sex, religion,
                  nationality, disability, sexual orientation or age.
                </span>
              </li>
              <li>
                <span className="">
                  Infringe any copyright, database right or trade mark of any
                  other person.
                </span>
              </li>
              <li>
                <span className="">Be likely to deceive any person.</span>
              </li>
              <li>
                <span className="">
                  Be made in breach of any legal duty owed to a third party,
                  such as a contractual duty or a duty of confidence.
                </span>
              </li>
              <li>
                <span className="">Promote any illegal activity.</span>
              </li>
              <li>
                <span className="">
                  Be threatening, abuse or invade another&rsquo;s privacy, or
                  cause annoyance, inconvenience or needless anxiety.
                </span>
              </li>
              <li>
                <span className="">
                  Be likely to harass, upset, embarrass, alarm or annoy any
                  other person.
                </span>
              </li>
              <li>
                <span className="">
                  Be used to impersonate any person, or to misrepresent your
                  identity or affiliation with any person.
                </span>
              </li>
              <li>
                <span className="">
                  Give the impression that they emanate from us, if this is not
                  the case.
                </span>
              </li>
              <li>
                <span className="">
                  Advocate, promote or assist any unlawful act e.g. copyright
                  infringement or computer misuse.
                </span>
              </li>
            </ul>
            <p className="font-bold">
              <span>Accounts and Passwords</span>
            </p>
            <p>
              <span>
                We make use of LinkedIn to provide access to your information
                based on your LinkedIn vanityName.
              </span>
              <span className="">
                &nbsp;We have the right to disable any access, whether chosen by
                you or allocated by us, at any time, if in our reasonable
                opinion you have failed to comply with any of the provisions of
                these terms of use. If you have any concerns regarding your user
                LinkedIn identification code or become aware of any misuse then
                you must inform us immediately.
              </span>
            </p>
            <p className="font-bold">
              <span>Ownership of rights</span>
            </p>
            <p>
              <span className="">
                We are the owner or the licensee of all intellectual property
                rights in our site, and in the material published on it. Those
                works are protected by copyright laws and treaties around the
                world. All such rights are reserved.
              </span>
            </p>
            <p>
              <span className="">
                The content on our site is made available for your personal
                non-commercial use only and you may only download such content
                for the purpose of using this site. You must not use any part of
                the content on our site for commercial purposes without
                obtaining a licence to do so from us or our licensors. Other
                than as set out in these terms of use, any other use of the
                content on this site is strictly prohibited and you agree not to
                (and agree not to assist any third party to) copy, reproduce,
                transmit, publish, display, distribute, commercially exploit or
                create derivative works of such content.
              </span>
            </p>
            <p className="font-bold">
              <span>No reliance on information</span>
            </p>
            <p>
              <span className="">
                The content on our site is provided for general information
                only. It is not intended to amount to advice on which you should
                rely. Although we make reasonable efforts to update the
                information on our site, we make no representations, warranties
                or guarantees, whether express or implied, that the content on
                our site is accurate, complete or up-to-date.
              </span>
            </p>
            <p className="font-bold">
              <span>Limitation of our liability</span>
            </p>
            <p>
              <span className="">
                Nothing in these terms of use excludes or limits our liability
                for death or personal injury arising from our negligence, or our
                fraud or fraudulent misrepresentation, or any other liability
                that cannot be excluded or limited by English law.
              </span>
            </p>
            <p>
              <span className="">
                To the extent permitted by law, we exclude all conditions,
                warranties, representations or other terms which may apply to
                our site or any content on it, whether express or implied.
                <br />
                We will not be liable to any user for any loss or damage,
                whether in contract, tort (including negligence), breach of
                statutory duty, or otherwise, even if foreseeable, arising under
                or in connection with your use of, or inability to use, our site
                or your use of or reliance on any content displayed on it.
              </span>
            </p>
            <p>
              <span className="">
                We will not be liable for loss of profits, sales, business, or
                revenue, business interruption, loss of anticipated savings,
                loss of business opportunity, goodwill or reputation or any
                indirect or consequential loss or damage.
              </span>
            </p>
            <p>
              <span className="">
                We will not be liable for any loss or damage caused by a virus,
                distributed denial-of-service attack, or other technologically
                harmful material that may infect your computer equipment,
                computer programs, data or other proprietary material due to
                your use of our site or to your downloading of any content on
                it, or on any website linked to our site. Different limitations
                and exclusions of liability will apply to liability arising as a
                result of the supply of any goods or services by us to you,
                which will be set out in our terms and conditions of supply.
              </span>
            </p>
            <p className="font-bold">
              <span>Viruses</span>
            </p>
            <p>
              <span className="">
                We do not guarantee that our site will be secure or free from
                bugs or viruses. You are responsible for configuring your
                information technology, computer programmes and platform in
                order to access our site. You should use your own virus
                protection software.
              </span>
            </p>
            <p>
              <span className="">
                You must not misuse our site by knowingly introducing viruses,
                trojans, worms, logic bombs or other material which is malicious
                or technologically harmful. You must not attempt to gain
                unauthorised access to our site, the server on which our site is
                stored or any server, computer or database connected to our
                site. You must not attack our site via a denial-of-service
                attack or a distributed denial-of service attack. Third party
                links and resources on our site &ndash; Where our site contains
                links to other sites and resources provided by third parties,
                these links are provided for your information only and should
                not be interpreted as endorsement by us of those linked
                websites. We have no control over the contents of those sites or
                resources and accept no responsibility for them or for any loss
                or damage that may arise from your use of them.
              </span>
            </p>
            <p className="font-bold">
              <span>Suspension and termination</span>
            </p>
            <p>
              <span className="">
                We will determine, in our discretion, whether there has been a
                breach of these terms of use through your use of our site. When
                a breach of this policy has occurred, we may take such action as
                we deem appropriate. Failure to comply with these terms of use
                may result in our taking all or any of the following actions:
              </span>
            </p>
            <ul>
              <li>
                <span className="">
                  Immediate, temporary or permanent withdrawal of your right to
                  use our site.
                </span>
              </li>
              <li>
                <span className="">
                  Immediate, temporary or permanent removal of any
                  posting/material uploaded by you to our site.
                </span>
              </li>
              <li>
                <span className="">Issue of a warning to you.</span>
              </li>
              <li>
                <span className="">
                  Legal proceedings against you for reimbursement of all costs
                  on an indemnity basis (including, but not limited to,
                  reasonable administrative and legal costs) resulting from the
                  breach.
                </span>
              </li>
              <li>
                <span className="">Further legal action against you.</span>
              </li>
              <li>
                <span className="">
                  Disclosure of such information to law enforcement authorities
                  as we reasonably feel is necessary.
                </span>
              </li>
            </ul>
            <p>
              <span className="">
                We exclude liability for actions taken in response to breaches
                of these terms of use. The responses described in this policy
                are not limited, and we may take any other action we reasonably
                deem appropriate.
              </span>
            </p>
            <p className="font-bold">
              <span>Applicable law</span>
            </p>
            <p>
              <span>P</span>
              <span className="">
                lease note that these terms of use, its subject matter and its
                formation (and any non-contractual disputes or claims) are
                governed by English law. We both agree to the exclusive
                jurisdiction of the courts of Ireland.
              </span>
            </p>
            <p className="font-bold">
              <span className="">Thank you for visiting our site.</span>
            </p>
            <p>
              <span className="">ELTic Limited,</span>
            </p>
            <p className="font-bold">
              <span>Terms &amp; Conditions</span>
            </p>
            <p>
              <span className="">
                By using this site you agree to our Terms of use. Whilst every
                effort is made to ensure that all information included in our
                website is accurate, users are advised that they should take
                appropriate precautions to verify such information. ELTic
                limited expressly disclaims all liability for any direct,
                indirect or consequential loss or damage occasioned by the
                user&rsquo;s reliance on any statements, information, or advice
                contained in this web site. ELTic Limited is not responsible for
                the content of external internet sites.
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
