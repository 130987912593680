import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { ErrorBoundary } from "react-error-boundary";

function SetDocumentTitle() {
  useEffect(() => {
    document.title = process.env.REACT_APP_DOCUMENT_TITLE || "ELTic";
  }, []);

  return null;
}

const fallbackRender = ({ error, resetErrorBoundary }: any) => {

  var globalVars =
  localStorage.getItem("candidateToken") +
  ";" +
  localStorage.getItem("candidateFullname") +
  ";" +
  localStorage.getItem("candidateVanityName");

  let formData =
    '{"FieldName": "NIU", "FieldValue": "@Message@", "FieldName2": "@Global@", "FieldValue2": "@Stack@"}'
      .replace(
        "@Message@",
        error.message.replaceAll('"', "").replaceAll("'", "`").replaceAll("https://", "").replaceAll("http://", "").replaceAll(/\n/g, "")
      )
      .replace("@Global@", globalVars.replaceAll('"', "")
      .replaceAll("'", "")
      .replaceAll("https://", "")
      .replaceAll("http://", "")
      .replaceAll(/\n/g, "")
      .substring(0, 1000))
      .replace(
        "@Stack@",
        error.stack
          .replaceAll('"', "")
          .replaceAll('/', "_")
          .replaceAll("'", "")
          .replaceAll("localhost", "")
          .replaceAll("https://", "")
          .replaceAll("http://", "")
          .replaceAll(/\n/g, "")
          .replaceAll(/\r\n/g, "")
          .replaceAll("bundle.js", "")
          .substring(0, 1000)
      );

  var responseStatusCode = 200;
  const apiURL = process.env.REACT_APP_API_URL;

  fetch(apiURL + "ErrorLog/?id=30646", {
    method: "POST",
    headers: {
      charset: "UTF-8",
      "content-type": "application/json",
      accept: "application/json",
    },
    body: formData,
  })
    .then((response) => {
      responseStatusCode = response.status;
      return response.json();
    })
    .then((response) => {
      if (responseStatusCode === 200) {
        console.log("Error Submitted Success");
      }
    })
    .catch((err) => {
      console.log(error);
    });

  return (
    <>
      <div className="sm:hidden absolute bg-[url('images/mainimage.jpg')] bg-cover bg-no-repeat bg-center h-screen w-full z-[0]"></div>
      <div
        role="alert"
        className="flex flex-col w-full h-[calc(100vh-2px)] items-center justify-center bg-white text-left text-21xl text-offers-green font-h2 font-sofia-pro box-border"
      >
        <div className="sm:hidden flex flex-col p-10 w-[361] z-[10] rounded-xl bg-white shadow-[0px_3px_9px_rgba(0,_0,_0,_0.25)]  items-center justify-center gap-[19px]">
          <div className="flex flex-col items-center justify-center gap-[10px] p-[10px]">
            <div className="flex flex-row w-full items-center justify-center gap-[2px] tracking-[0.01em] font-semibold font-sofia-pro text-center text-[36px] text-highlight">
              Something went wrong
            </div>
          </div>
          <p style={{ color: "red" }}>{error.message}</p>
          <p style={{ color: "red" }}>{error.stack}</p>
          <div
            onClick={resetErrorBoundary}
            className="w-[361px] flex flex-col p-2.5 box-border items-start justify-start text-center text-base text-white"
          >
            <div className="self-stretch rounded-[47px] bg-button_primary h-[47px] shrink-0 flex flex-row py-0 px-[39px] box-border items-center justify-center gap-[10px]">
              <div className="relative leading-[29px] font-semibold capitalize">
                Reload Page
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:flex sm:flex-col p-[10px_0px_0px_0px] w-[95%] z-[10] rounded-xl bg-white shadow-[0px_3px_9px_rgba(0,_0,_0,_0.25)]  items-center justify-center gap-[19px]">
          <div className="flex flex-col w-full  gap-[10px]">
            <div className="flex flex-row w-full items-center justify-center gap-[2px] tracking-[0.01em] font-semibold font-sofia-pro text-center text-[36px] text-highlight">
              Something went wrong
            </div>
          </div>
          <p style={{ color: "red" }}>{error.message}</p>
          <div
            onClick={resetErrorBoundary}
            className="w-[95%] flex flex-col p-2.5 box-border items-start justify-start text-center text-base text-white"
          >
            <div className="self-stretch rounded-[47px] bg-button_primary h-[47px] shrink-0 flex flex-row py-0 px-[39px] box-border items-center justify-center gap-[10px]">
              <div className="relative leading-[29px] font-semibold capitalize">
                Reload Page
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <SetDocumentTitle />
    <ErrorBoundary fallbackRender={fallbackRender} onReset={(details) => {}}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </>
);
