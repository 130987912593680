import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./home";

import PageNotFound from "./page_notfound";
import PaymentLanding from "./payment_landing";
import PaymentCancel from "./payment_cancel";
import Version from "./version";
import TermsAndConditions from "./termsandconditions";
import PrivacyPolicy from "./privacypolicy";
import PaymentLandingOrder from "./payment_landing_order";

export default class routes extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payment_cancel" element={<PaymentCancel />} />
        <Route path="/payment_landing" element={<PaymentLanding />} />
        <Route path="/version" element={<Version />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/payment_landing_order" element={<PaymentLandingOrder />} />
        
        <Route path="*" element={<PageNotFound />} />
        <Route />
      </Routes>
    );
  }
}


