import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

const PaymentLanding = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [referredById] = useState(searchParams.get("r") || "1");
  const [message, setMessage] = useState("");
  const [messageColour, setMessageColour] = useState("text-red-100");

  const CustomToastContent = ({ message, customClassName }) => {
    return <div className={customClassName}>{message}</div>;
  };

  return (
    <>
      <Helmet>
        <title>Payment received</title>
      </Helmet>
      <div className="flex flex-col w-full h-[calc(100vh-2px)] items-center justify-center bg-white text-left text-21xl text-offers-green font-h2 font-sofia-pro box-border">
        <div className="flex flex-col p-10 w-[361] z-[10] rounded-xl bg-white shadow-[0px_3px_9px_rgba(0,_0,_0,_0.25)]  items-center justify-center gap-[19px]">
          <div className="flex justify-center">
            {" "}
            <img
              src="/images/logoweb.png"
              alt="ELTic"
              className="cursor-pointer w-[200px] shrink-0 p-2"
              onClick={() => window.open("https://eltic.io", "_blank")}
            />
          </div>
          <div className="relative pt-10 font-semibold font-sofia-pro text-[18px] text-normal">
            Thank you, payment was received.
            <br />
            You will receive an email shortly with your Action List.
            <br />
            <br />
            <div className="flex justify-between pt-6 gap-4 mb-4">
              <div
                onClick={() => navigate("/?r=" + referredById)}
                className="flex flex-row bg-button_primary self-stretch rounded-[40px] h-[48px] w-full text-white box-border border-button_primary items-center justify-center gap-[10px] cursor-pointer"
              >
                <div className="relative font-semibold">
                  Order another Action List
                </div>
              </div>
              
            </div>
            {/* <br />
              We record the list we supplied you and always try to provide new records for the next Action List.<br />
              <b>Please note:</b>  When candidates inform you to stop contacting them, <br />
              click on the "unsubscribe" link in the Action List against their name,<br />
              and they will not appear in your future Action Lists. */}
          </div>
          {message && (
            <div className="w-full text-darkred text-center text-[18px]">
              <center>
                <div className="font-extrabold text-xl font-sofia-pro text-center pt-5 items-center justify-center">
                  <span className={messageColour}>{message}</span>
                </div>
              </center>
            </div>
          )}
          <Toaster />
        </div>
      </div>
    </>
  );
};

export default PaymentLanding;
