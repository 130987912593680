import React, { useState, useEffect } from "react";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
// 

import "font-awesome/css/font-awesome.min.css";

const Home = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [referredById] = useState(searchParams.get("r") || "1");
  const [orderTypeId, setOrderTypeId] = useState(searchParams.get("ot") || "4");
  const [calcReload, setCalcReload] = useState(0);
  const [selectedJobTitle, setSelectedJobTitle] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationDescriptionList, setLocationDescriptionList] = useState([]);
  const [totalCost, setTotalCost] = useState(0.0);
  const [ownNumberRows, setOwnNumberRows] = useState(200);

  const [rowsAvailable, setRowsAvailable] = useState(100000);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [vanityName, setVanityName] = useState("");

  const [professionList, setProfessionList] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState(1);

  const [selectedProfessionArray, setSelectedProfessionArray] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedRadius, setSelectedRadius] = useState(5);
  const [keywords, setKeywords] = useState("");

  const [skillList, setSkillList] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("gb");
  const [selectedPQE, setSelectedPQE] = useState("");
  const [selectedPQETo, setSelectedPQETo] = useState("");

  const [openToWork, setOpenToWork] = useState(0);
  const [hasEmail, setHasEmail] = useState("");
  const [dateLastUpdated, setDateLastUpdated] = useState("");
  const [noOfRecordsRequested, setNoOfRecordsRequested] = useState("100");
  const [professionDescriptionList, setProfessionDescriptionList] =
    useState("");
  const [skillDescriptionList, setSkillDescriptionList] = useState("");
  const [jobTitleDescriptionList, setJobTitleDescriptionList] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [apiOrderToken, setApiOrderToken] = useState("");
  const [performPayment, setPerformPayment] = useState(0);

  const validateEmail = (email) => {
    const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexPattern.test(email);
  };

  const apiURL = process.env.REACT_APP_API_URL;
  const hostToReturnUrl = process.env.REACT_APP_URL;

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      boxShadow: "none",
      borderRadius: "20px",
      backgroundColor: state.isSelected ? "#ff7a00" : styles.color,
      color: "#000",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#ff7a00",
      },
    }),
    control: (styles) => ({
      ...styles,
      // height: 52,
      boxShadow: "none",
      borderRadius: "20px",
      border: "1px solid #ccc",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#fff",
      },
      "&:focus": {
        border: "1px solid #ff7a00",
      },
      "&:acitve": {
        border: "2px solid #ff7a00",
      },
    }),
  };

  // const fetchJobTitleList = (inputValue, callback) => {
  //   let responseStatusCode = 0;
  //   fetch(apiURL + "lookupoutside/?name=JobTitleLookup::" + inputValue, {
  //     method: "GET",
  //     headers: {
  //       charset: "UTF-8",
  //       "content-type": "application/json",
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       responseStatusCode = response.status;
  //       return response.json();
  //     })
  //     .then((response) => {
  //       if (responseStatusCode === 200 || responseStatusCode === 201) {
  //         let jsonResult = JSON.parse(response);
  //         if (jsonResult !== null) {
  //           callback(
  //             jsonResult.map((tmz) => ({
  //               value: tmz.JobTitleId,
  //               label: tmz.JobTitleName,
  //             }))
  //           );
  //         }
  //       } else if (responseStatusCode === 401) {
  //         toast((t) => (
  //           <CustomToastContent
  //             message={
  //               "Oops - Could not load Candidate Job Title List - " +
  //               responseStatusCode
  //             }
  //             customClassName={
  //               "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
  //             }
  //           />
  //         ));
  //       }
  //     })
  //     .catch((err) => {
  //       toast((t) => (
  //         <CustomToastContent
  //           message={err.message}
  //           customClassName={
  //             "flex items-center justify-center text-darkred bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
  //           }
  //         />
  //       ));
  //     });
  // };

  const fetchLocationList = (inputValue, callback) => {
    let responseStatusCode = 0;

    fetch(
      apiURL +
        "lookupoutside/?name=LocationList::" +
        selectedCountryCode +
        "::" +
        inputValue,
      {
        method: "GET",
        headers: {
          charset: "UTF-8",
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => {
        responseStatusCode = response.status;
        return response.json();
      })
      .then((response) => {
        if (responseStatusCode === 200 || responseStatusCode === 201) {
          let jsonResult = JSON.parse(response);
          console.log(jsonResult);
          if (jsonResult !== null) {
            callback(
              jsonResult.map((tmz) => ({
                value: tmz.Name,
                label: tmz.LocationShort,
              }))
            );
          }
        } else if (responseStatusCode === 401) {
          toast((t) => (
            <CustomToastContent
              message={
                "Oops - Could not load Location List - " + responseStatusCode
              }
              customClassName={
                "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
              }
            />
          ));
        }
      })
      .catch((err) => {
        toast((t) => (
          <CustomToastContent
            message={err.message}
            customClassName={
              "flex items-center justify-center text-darkred bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
            }
          />
        ));
      });
  };

  const fetchProfessionList = () => {
    let responseStatusCode = 0;

    fetch(
      apiURL + "lookupoutside/?name=ProfessionList::" + selectedCountryCode,
      {
        method: "GET",
      }
    )
      .then((response) => {
        responseStatusCode = response.status;
        return response.json();
      })
      .then((response) => {
        if (responseStatusCode === 200 || responseStatusCode === 201) {
          let jsonResult = JSON.parse(response);
          if (jsonResult !== null) {
            let options = jsonResult.map((tmz) => ({
              value: tmz.ProfessionId,
              label: tmz.ProfessionName,
            }));
            setProfessionList(options);
          }
        } else if (responseStatusCode === 401) {
          toast((t) => (
            <CustomToastContent
              message={
                "Oops - Could not load Profession List - " + responseStatusCode
              }
              customClassName={
                "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
              }
            />
          ));
        }
      })
      .catch((err) => {
        toast((t) => (
          <CustomToastContent
            message={"Oops - " + err.message}
            customClassName={
              "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
            }
          />
        ));
      });
  };

  const fetchSkillList = () => {
    let responseStatusCode = 0;
    fetch(
      apiURL + "lookupoutside/?name=SkillList::" + selectedProfessionArray,
      {
        method: "GET",
      }
    )
      .then((response) => {
        responseStatusCode = response.status;
        return response.json();
      })
      .then((response) => {
        if (responseStatusCode === 200 || responseStatusCode === 201) {
          let jsonResult = JSON.parse(response);
          if (jsonResult !== null) {
            let options = jsonResult.map((tmz) => ({
              value: tmz.SkillId,
              label: tmz.SkillDescription,
            }));
            setSkillList(options);
          }
        } else if (responseStatusCode === 401) {
          toast((t) => (
            <CustomToastContent
              message={
                "Oops - Could not load Skill List - " + responseStatusCode
              }
              customClassName={
                "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
              }
            />
          ));
        }
      })
      .catch((err) => {
        toast((t) => (
          <CustomToastContent
            message={"Oops - " + err.message}
            customClassName={
              "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
            }
          />
        ));
      });
  };

  const fetchCountryList = () => {
    let responseStatusCode = 0;
    fetch(apiURL + "lookupoutside/?name=Countries", {
      method: "GET",
    })
      .then((response) => {
        responseStatusCode = response.status;
        return response.json();
      })
      .then((response) => {
        if (responseStatusCode === 200 || responseStatusCode === 201) {
          let jsonResult = JSON.parse(response);
          if (jsonResult !== null) {
            let options = jsonResult.map((tmz) => ({
              value: tmz.CountryCode,
              label: tmz.CountryName,
            }));
            setCountries(options);
          }
        } else if (responseStatusCode === 401) {
          toast((t) => (
            <CustomToastContent
              message={
                "Oops - Could not load Skill List - " + responseStatusCode
              }
              customClassName={
                "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
              }
            />
          ));
        }
      })
      .catch((err) => {
        toast((t) => (
          <CustomToastContent
            message={"Oops - " + err.message}
            customClassName={
              "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
            }
          />
        ));
      });
  };

  function EmailModal({ isOpen, onClose, onEmailSubmit }) {
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleSubmit = (e) => {
      e.preventDefault();
      // Perform email validation
      if (isValidEmailFormat(email)) {
        setIsValidEmail(true);
        // Pass the email to the parent component
        onEmailSubmit(email);
        // Close the modal
        onClose();
      } else {
        setIsValidEmail(false);
      }
    };

    const isValidEmailFormat = (email) => {
      // Simple email validation using a regular expression
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    return (
      <div
        className={`modal ${
          isOpen ? "block" : "hidden"
        } fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 font-sofia-pro`}
      >
        <div className="modal-dialog bg-white rounded-lg p-6 w-80 mx-auto mt-24">
          <div className="flex items-center pb-2">
            <div className="text-2xl w-[70%] text-left relative h-[40px] shrink-0 font-semibold">
              Enter your email address
            </div>
            <div className="w-[30%] text-right justify-end">
              <div
                className="rounded-[40px] text-center font-semibold  box-border border border-solid border-brown-100 relative w-[38px] p-[0px_10px] leading-[28px] inline-block text-brown-100 capitalize cursor-pointer"
                onClick={onClose}
              >
                X
              </div>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="text-center items-center"
            id="form1"
          >
            <input
              type="text"
              placeholder="Email address to receive list"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-[90%] rounded-[40px] pt-2  border ${
                isValidEmail ? "" : "border-red-500"
              } rounded-md p-2 mb-2`}
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm">
                Please enter a valid email address.
              </p>
            )}
            <div className="flex pt-3">
              <button
                type="submit"
                className="mx-auto block bg-button_primary rounded-[40px] h-[48px] w-[70%] text-white border-button_primary items-center justify-center cursor-pointer"
              >
                Continue to order
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  const CustomToastContent = ({ message, customClassName }) => {
    return <div className={customClassName}>{message}</div>;
  };

  // const row_calc = () => {
  //   let responseStatusCode = 0;
  //   const formData = {
  //     jtl: encodeURIComponent(jobTitleDescriptionList),
  //     pl: encodeURIComponent(professionDescriptionList),
  //     sl: encodeURIComponent(skillDescriptionList),
  //     cc: selectedCountryCode,
  //     ll: encodeURIComponent(selectedLocation),
  //     rid: referredById,
  //   };

  //   if (selectedPQE !== "") {
  //     formData.pqe = selectedPQE;
  //   }
  //   if (selectedPQETo !== "") {
  //     formData.pqeTo = selectedPQETo;
  //   }

  //   if (selectedRadius !== "") {
  //     formData.radius = selectedRadius;
  //   }

  //   fetch(apiURL + "rowcalc", {
  //     method: "POST",
  //     headers: {
  //       charset: "UTF-8",
  //       "content-type": "application/json",
  //       accept: "application/json",
  //     },

  //     body: JSON.stringify(formData),
  //   })
  //     .then((response) => {
  //       responseStatusCode = response.status;
  //       return response.json();
  //     })
  //     .then((response) => {
  //       if (responseStatusCode === 200 || responseStatusCode === 201) {
  //         let jsonResult = JSON.parse(response);
  //         if (jsonResult[0] != null) {
  //           setRowsAvailable(jsonResult[0].row_count);
  //           //setTotalCost(jsonResult[0].price_total);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       toast((t) => (
  //         <CustomToastContent
  //           message={"Oops - " + err.message}
  //           customClassName={
  //             "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
  //           }
  //         />
  //       ));
  //     });
  // };

  const order_send = (emailTyped) => {
    let responseStatusCode = 0;

    let formData = {};

    if (keywords !== null && keywords !== "") {
      formData.Keywords = encodeURIComponent(keywords);
    }

    if (
      professionDescriptionList !== null &&
      professionDescriptionList !== ""
    ) {
      formData.ProfessionList = encodeURIComponent(professionDescriptionList);
    }

    if (skillDescriptionList !== null && skillDescriptionList !== "") {
      formData.SkillList = encodeURIComponent(skillDescriptionList);
    }
    else {
      formData.SkillList = "All";
    }

    if (selectedCountryCode !== null && selectedCountryCode !== "") {
      formData.CountryCode = selectedCountryCode;
    }

    if (selectedLocation !== null && selectedLocation !== "") {
      formData.LocationList = encodeURIComponent(selectedLocation);
    }

    if (referredById !== null && referredById !== "") {
      formData.ReferrerId = referredById;
    }

    if (ownNumberRows !== null && ownNumberRows !== "") {
      formData.NoOfRecordsRequested = ownNumberRows;
    }

    if (selectedPQE !== null && selectedPQE !== "") {
      formData.Pqe = selectedPQE;
    }

    if (selectedPQETo !== null && selectedPQETo !== "") {
      formData.PqeTo = selectedPQETo;
    }

    if (totalCost !== null && totalCost !== "") {
      formData.TotalCost = totalCost;
    }

    if (selectedRadius !== null) {
      formData.Radius = selectedRadius;
    }
    else {
      formData.Radius = 5;
    }

    if (rowsAvailable !== null && rowsAvailable !== "") {
      formData.Quantity = rowsAvailable;
    }

    if (emailTyped !== null && emailTyped !== "") {
      formData.Email = encodeURIComponent(emailTyped);
    }

    if (orderTypeId !== null && orderTypeId !== "") {
      formData.QueryType = orderTypeId;
    }

    if (vanityName !== null && vanityName !== "") {
      formData.VanityNameList = encodeURIComponent(vanityName);
    }

    //console.log(JSON.stringify(formData))
    fetch(apiURL + "order", {
      method: "POST",
      headers: {
        charset: "UTF-8",
        "content-type": "application/json",
        accept: "application/json",
      },

      body: JSON.stringify(formData),
    })
      .then((response) => {
        responseStatusCode = response.status;
        return response.json();
      })
      .then((response) => {
        if (responseStatusCode === 200 || responseStatusCode === 201) {
          let jsonResult = JSON.parse(response);
          if (jsonResult[0] != null) {
            setApiOrderToken(jsonResult[0].api_order_token);
            //setPerformPayment(1);
            toast((t) => (
              <CustomToastContent
                message={
                  "Successfully saved"
                }
                customClassName={
                  "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
                }
              />
            ));
          }
        } else {
          toast((t) => (
            <CustomToastContent
              message={
                "Oops - could not save the request. " + responseStatusCode
              }
              customClassName={
                "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
              }
            />
          ));
        }
      })
      .catch((err) => {
        toast((t) => (
          <CustomToastContent
            message={"Oops - " + err.message}
            customClassName={
              "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
            }
          />
        ));
      });
  };

  let stripePromise;

  // const getStripe = () => {
  //   if (!stripePromise) {
  //     stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  //   }
  //   return stripePromise;
  // };

  // async function payment_request(orderToken) {
  //   const stripe = await getStripe();

  //   if (orderToken != "") {
  //     let responseStatusCode = 0;
  //     let REACT_APP_STRIPE_PUBLIC_KEY;
  //     let productName = "";
  //     if (orderTypeId == 3) productName = "Refresh Solicitor List";
  //     if (orderTypeId == 4) productName = "Bulk Search Solicitor List";
  //     let formData = {
  //       OrderToken: orderToken,
  //       Amount: totalCost,
  //       ProductName: productName,
  //       Quantity: ownNumberRows,
  //       ReferrerId: referredById,
  //       HostToReturnUrl: hostToReturnUrl,
  //     };

  //     fetch(apiURL + "payment", {
  //       method: "POST",
  //       headers: {
  //         charset: "UTF-8",
  //         "content-type": "application/json",
  //         accept: "application/json",
  //       },

  //       body: JSON.stringify(formData),
  //     })
  //       .then((response) => {
  //         responseStatusCode = response.status;
  //         return response.json();
  //       })
  //       .then((response) => {
  //         if (responseStatusCode === 200 || responseStatusCode === 201) {
  //           let jsonResult = JSON.parse(response);
  //           if (jsonResult[0] != null) {
  //             const { error } = stripe.redirectToCheckout({
  //               sessionId: jsonResult[0].sessionId,
  //             });
  //           }
  //         } else {
  //           toast((t) => (
  //             <CustomToastContent
  //               message={
  //                 "Oops - payment was not successful. Code: " +
  //                 responseStatusCode
  //               }
  //               customClassName={
  //                 "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
  //               }
  //             />
  //           ));
  //         }
  //       })
  //       .catch((err) => {
  //         toast((t) => (
  //           <CustomToastContent
  //             message={"Oops - " + err.message}
  //             customClassName={
  //               "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
  //             }
  //           />
  //         ));
  //       });
  //   }
  // }

  // const row_own_number_calc = (typed_row_number, orderTypeId) => {
  //   let responseStatusCode = 0;
  //   fetch(
  //     apiURL +
  //       "rowcalc?recordNumber=" +
  //       typed_row_number +
  //       "&typeId=" +
  //       orderTypeId,
  //     {
  //       method: "GET",
  //     }
  //   )
  //     .then((response) => {
  //       responseStatusCode = response.status;
  //       return response.json();
  //     })
  //     .then((response) => {
  //       if (responseStatusCode === 200 || responseStatusCode === 201) {
  //         let jsonResult = JSON.parse(response);
  //         if (jsonResult[0] != null) {
  //           setTotalCost(jsonResult[0].price_total);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       toast((t) => (
  //         <CustomToastContent
  //           message={"Oops - " + err.message}
  //           customClassName={
  //             "flex items-center justify-center text-gray-1400 bg-gray-200 border border-solid border-button_primary font-sofia-pro m-[-30px] p-3 rounded-[40px]"
  //           }
  //         />
  //       ));
  //     });
  // };

  const currencyFractionDigits = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "EUR",
  }).resolvedOptions().maximumFractionDigits;

  const handleEmailSubmit = (email) => {
    // Handle the email submission here, such as sending it to a server
    setEmail(email);
    order_send(email);
  };

  const handleTextAreaChange = (value) => {
    setVanityName(value);
    const lines = value.split("\n");
    // Filter out empty lines and count the remaining non-empty lines
    const nonEmptyLinesCount = lines.filter(
      (line) => line.trim() !== ""
    ).length;
    if (ownNumberRows != nonEmptyLinesCount) {
      setOwnNumberRows(nonEmptyLinesCount);
      //setCalcReload(1);
      console.log("reload 2");
    }
  };

  const userEmail = ["hello", "eltic.io"].join("@");

  useEffect(() => {
    fetchCountryList();
    fetchLocationList();
    //fetchJobTitleList();
    fetchProfessionList();
    //row_calc();
  }, []);

  useEffect(() => {
    fetchProfessionList();
    fetchLocationList();
  }, [selectedCountryCode]);

  useEffect(() => {
    fetchSkillList();
  }, [selectedProfession]);

  //useEffect(() => {
    // row_calc();
    // row_own_number_calc(ownNumberRows, orderTypeId);
    // setCalcReload(0);
  //}, [calcReload, orderTypeId]);

  // useEffect(() => {
  //   payment_request(apiOrderToken);
  //   setPerformPayment(0);
  // }, [performPayment]);

  return (
    <>
      <Helmet>
        <title>Move Score List</title>
        <meta
          name="description"
          content="Use a Solicitor List with Move Scores to contact candidates"
        />
        <meta
          name="keywords"
          content="finding solicitors with a high propability to move job"
        />
      </Helmet>
      <div className="relative font-sofia-pro bg-cover bg-no-repeat bg-center h-full w-full">
        <div className="flex flex-col w-full h-screen box-border text-left min-h-screen text-normal font-sofia-pro ">
          <div className="flex flex-col w-full box-border items-center   ">
            <div className="flex w-[70%] lg:w-[80%] md:w-[90%] sm:w-full h-full items-end justify-center box-border">
              <div className="flex flex-col w-full bg-white text-left items-center justify-top font-sofia-pro box-border">
                <div className="flex flex-col w-full bg-white text-left items-center justify-top font-sofia-pro box-border">
                  <div className="flex flex-col items-center w-full">
                    <div className="w-full border flex flex-row">
                      <div className="flex justify-center items-center w-[50%]">
                        <img
                          src="/images/logoweb.png"
                          alt="ELTic"
                          className="cursor-pointer w-[160px] shrink-0 p-2"
                          onClick={() =>
                            (window.location.href = "https://eltic.io")
                          }
                        />
                      </div>
                      <div className="flex justify-center items-center w-[50%]">
                        <h1 className="text-8xl sm:text-2xl">
                          Move Score List
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                {orderTypeId == 4 && (
                  <div className="text-2xl pt-3 text-center">
                    <h2 className="text-5xl sm:text-2xl">
                      What candidates are you looking for in the Legal Industry?
                    </h2>
                  </div>
                )}
                {orderTypeId == 3 && (
                  <div className="text-2xl pt-5 text-center ">
                    What candidates do you require the latest information of?
                    <br />
                    Or click here for a&nbsp;
                    <a
                      href="#"
                      onClick={(e) => setOrderTypeId(4)}
                      className=" text-button_primary"
                    >
                      <u>Move Score - Solicitor List</u>
                    </a>
                  </div>
                )}

                {orderTypeId == 4 && (
                  <div className="p-3 w-4/5 order-2">
                    <div className="w-full">
                      <label className="block pb-2 pt-1 pl-1 font-sofia-pro text-sm font-medium text-normal">
                        Country
                      </label>
                      <Select
                        styles={customStyles}
                        onChange={(e) => {
                          setSelectedCountryCode(e.value);
                          //setCalcReload(1);
                        }}
                        value={Object.values(countries).filter(
                          (obj) => obj.value === selectedCountryCode
                        )}
                        options={countries}
                        placeholder="Select country"
                        className="w-full cursor-pointer z-[10]"
                      />
                    </div>
                    <div className="w-full pt-2">
                      <label className="block pb-1 pt-2  pl-1 font-sofia-pro text-sm font-medium text-normal">
                        Registered as Profession
                      </label>
                      <Select
                        name="searchProfession"
                        styles={customStyles}
                        // isMulti
                        closeMenuOnSelect={false}
                        defaultMenuIsOpen={false}
                        escapeClearsValue={true}
                        hideSelectedOptions={true}
                        placeholder="Choose profession"
                        onChange={(option) => {
                          // Check if option is an array
                          console.log(option);
                          if (Array.isArray(option)) {
                            setSelectedProfession(option);
                            let lList = [].concat(
                              option.map((opt) => opt.value)
                            );
                            let lFinallist = lList.join(",");
                            setSelectedProfessionArray(lFinallist);

                            let lList2 = [].concat(
                              option.map((opt) => opt.label)
                            );
                            let lFinallist2 = lList2.join("|");

                            setProfessionDescriptionList(lFinallist2);
                            //setCalcReload(1);
                          } else {
                            // Handle the case when option is not an array
                            // For example, when clearing the selection
                            setSelectedProfession(option);
                            setSelectedProfessionArray(option.value);
                            setProfessionDescriptionList(option.label);
                            //setCalcReload(1);
                          }
                        }}
                        value={selectedProfession}
                        options={professionList}
                        className="w-full cursor-pointer z-[9]"
                      />
                    </div>
                    <div className="w-full pt-2">
                      <label className="block pb-1 pt-2  pl-1 font-sofia-pro text-sm font-medium text-normal">
                        Skills linked to professions above
                      </label>
                      <Select
                        name="searchSkill"
                        styles={customStyles}
                        // isMulti
                        closeMenuOnSelect={false}
                        defaultMenuIsOpen={false}
                        escapeClearsValue={true}
                        hideSelectedOptions={true}
                        placeholder="Choose skill"
                        onChange={(option) => {
                          // Check if option is an array
                          if (Array.isArray(option)) {
                            setSelectedSkill(option);
                            let lList = [].concat(
                              option.map((opt) => opt.label)
                            );
                            let lFinallist = lList.join("|");
                            setSkillDescriptionList(lFinallist);
                            //setCalcReload(1);
                          } else {
                            // Handle the case when option is not an array
                            // For example, when clearing the selection
                            setSelectedSkill(option);
                            if (option) {
                              setSkillDescriptionList(option.label);
                            } else {
                              setSkillDescriptionList("");
                            }
                            //setCalcReload(1);
                          }
                        }}
                        value={selectedSkill}
                        options={skillList}
                        className="w-full cursor-pointer z-[8]"
                      />
                    </div>
                    <div className="w-full pt-2">
                      <label className="block pb-1 pt-2 pl-1  font-sofia-pro text-sm font-medium text-normal">
                        Keywords (e.g. adoption|asylum)
                      </label>
                      {/* <Select
                        name="searchJobTitle"
                        styles={customStyles}
                        isMulti
                        closeMenuOnSelect={false}
                        defaultMenuIsOpen={false}
                        escapeClearsValue={true}
                        hideSelectedOptions={true}
                        placeholder="Choose job title(s)"
                        onChange={(option) => {
                          setSelectedJobTitle(option);

                          let lList = [].concat(option.map((opt) => opt.label));
                          let lFinallist = lList.join("|");
                          setJobTitleDescriptionList(lFinallist);

                          setCalcReload(1);
                        }}
                        value={selectedJobTitle}
                        options={jobTitleList}
                        className="w-full cursor-pointer"
                      /> */}
                      {/* <AsyncCreatableSelect
                        name="searchJobTitle"
                        styles={customStyles}
                        closeMenuOnSelect={true}
                        isMulti
                        escapeClearsValue={false}
                        placeholder="Type to search ..."
                        onChange={(selectedOptions, action) => {
                          if (action != null) {
                            if (action.action === "select-option") {
                              setSelectedJobTitle(selectedOptions.label);

                              const selectedLabels = selectedOptions.map(
                                (option) => option.label
                              );
                              setSelectedJobTitle(selectedLabels.join("|"));
                              setJobTitleDescriptionList(
                                selectedLabels.join("|")
                              );
                              //console.log(jobTitleDescriptionList)
                              setCalcReload(1);
                            }
                            if (action.action === "create-option") {
                              setSelectedJobTitle(selectedOptions.label);

                              const selectedLabels = selectedOptions.map(
                                (option) => option.label
                              );
                              setSelectedJobTitle(selectedLabels.join("|"));
                              setJobTitleDescriptionList(
                                selectedLabels.join("|")
                              );
                              setCalcReload(1);
                            }
                            if (action.action === "clear") {
                              alert("Going 3 ");
                              setSelectedJobTitle("");
                              setJobTitleDescriptionList([]);
                              setCalcReload(1);
                            }
                            if (
                              action.action === "remove-value" ||
                              action.action === "pop-value"
                            ) {
                              // Handle removing a single option
                              const selectedLabels = selectedOptions.map(
                                (option) => option.label
                              );
                              setSelectedJobTitle(selectedLabels.join("|"));
                              setJobTitleDescriptionList(
                                selectedLabels.join("|")
                              );
                              setCalcReload(1);
                            }
                          }
                        }}
                        loadOptions={fetchJobTitleList}
                        //defaultInputValue={selectedJobTitle}
                        className="w-[350px] z-[7]"
                      /> */}
                      <input
                        type="text"
                        name="keywords"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        placeholder=""
                        className="border border-light h-[20px] rounded-md p-2 text-2xl font-sofia-pro w-[350px]"
                      />
                    </div>
                    <div>{selectedJobTitle}</div>
                    <div className="w-full pt-2">
                      <label className="block pb-1 pt-2  pl-1 font-sofia-pro text-sm font-medium text-normal">
                        Location
                      </label>
                      <AsyncCreatableSelect
                        name="searchLocation"
                        styles={customStyles}
                        closeMenuOnSelect={true}
                        escapeClearsValue={false}
                        placeholder="Type to search ..."
                        onChange={(selectedOptions, action) => {
                          if (action != null) {
                            if (
                              action.action === "select-option" ||
                              action.action === "create-option"
                            ) {
                              if (Array.isArray(selectedOptions)) {
                                const selectedValues = selectedOptions.map(
                                  (option) => option.value
                                );

                                const selectedLabels = selectedOptions.map(
                                  (option) => option.label
                                );
                                
                                setSelectedLocation(selectedLabels.join("|"));
                                
                                setLocationDescriptionList(
                                  selectedValues.join("|")
                                );
                                //setCalcReload(1);
                              }
                              else {
                                setSelectedLocation(selectedOptions.value)
                              }
                            }

                            if (action.action === "clear") {
                              setSelectedLocation("");
                              setLocationDescriptionList("");
                              //setCalcReload(1);
                            }

                            if (
                              action.action === "remove-value" ||
                              action.action === "pop-value"
                            ) {
                              if (Array.isArray(selectedOptions)) {
                                // Handle removing a single option
                                const selectedValues = selectedOptions.map(
                                  (option) => option.value
                                );

                                const selectedLabels = selectedOptions.map(
                                  (option) => option.label
                                );
                                setSelectedLocation(selectedLabels.join("|"));
                                setLocationDescriptionList(
                                  selectedValues.join("|")
                                );
                                //setCalcReload(1);
                              }
                            }
                          }
                        }}
                        loadOptions={fetchLocationList}
                        className="w-[350px] z-[4]"
                      />
                    </div>
                    <div className="w-full pt-2">
                      <label className="font-sofia-pro text-sm font-medium text-normal">
                        &nbsp;&nbsp;Miles around location:&nbsp;
                      </label>
                      <input
                        type="text"
                        name="radius"
                        value={selectedRadius}
                        onBlur={(e) => {
                          setSelectedRadius(e.target.value);
                        }}
                        onChange={(e) => setSelectedRadius(e.target.value)}
                        className="border border-light h-[20px] rounded-md p-2 text-2xl font-sofia-pro w-[50px]"
                      />
                    </div>
                    <div className="w-full pt-2">
                      <label className="block pb-1 pt-2  pl-1 font-sofia-pro text-sm font-medium text-normal">
                        Professional Qualified Experience (PQE) years
                      </label>
                      <label className="font-sofia-pro text-sm font-medium text-normal">
                        &nbsp;&nbsp;from:&nbsp;
                      </label>
                      <input
                        type="text"
                        name="searchPQE"
                        value={selectedPQE}
                        onBlur={(e) => {
                          setSelectedPQE(e.target.value);
                        }}
                        onChange={(e) => setSelectedPQE(e.target.value)}
                        className="border border-light h-[20px] rounded-md p-2 text-2xl font-sofia-pro w-[50px]"
                      />
                      &nbsp;&nbsp;
                      <label className="font-sofia-pro text-sm font-medium text-normal">
                        to:&nbsp;
                      </label>
                      <input
                        type="text"
                        name="searchPQETo"
                        value={selectedPQETo}
                        onBlur={(e) => {
                          setSelectedPQETo(e.target.value);
                        }}
                        onChange={(e) => setSelectedPQETo(e.target.value)}
                        className="border border-light h-[20px] rounded-md p-2 text-2xl font-sofia-pro w-[50px]"
                      />
                    </div>
                    <div className="w-full pt-5">
                      {/* <div className="flex text-normal">
                        <div className="w-[50%] items-end">
                          Records available:
                        </div>
                        <div className="w-[50%] items-start">
                          {rowsAvailable.toLocaleString("en-UK")}
                          {rowsAvailable < ownNumberRows && (
                            <div className="text-button_primary">
                              We will fetch the needed records for you
                            </div>
                          )}
                        </div>
                      </div> */}
                      <div className="flex items-center justify-center mt-2">
                        <div className="w-[50%]">How many do you need:</div>
                        <div className="w-[50%]">
                          <input
                            name="ownNumber"
                            value={ownNumberRows}
                            size="6"
                            className="border border-light h-[20px] rounded-md p-2 text-2xl font-sofia-pro w-[100px]"
                            onChange={(e) => {
                              setOwnNumberRows(e.target.value);
                              row_own_number_calc(e.target.value, orderTypeId);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="flex items-center justify-center mt-2">
                        <div className="w-[50%]">Total price:</div>
                        <div className="w-[50%]">
                          {totalCost == 100 ? (
                            <span>minimum order €100</span>
                          ) : (
                            <>
                              €
                              {totalCost.toLocaleString("en-UK", {
                                maximumFractionDigits: currencyFractionDigits,
                              })}
                            </>
                          )}
                        </div>
                      </div> */}
                    </div>
                    <div className="w-full">
                      <div className="flex justify-between pt-7 gap-4 mb-4">
                        {ownNumberRows === 0}
                        <button
                          onClick={() => setIsModalOpen(true)}
                          disabled={ownNumberRows === 0}
                          className="flex flex-row bg-button_primary self-stretch disabled:text-gray-500 rounded-[40px] h-[48px] w-full text-white box-border border-button_primary items-center justify-center gap-[10px] cursor-pointer"
                        >
                          <div className="relative font-semibold">
                            Order Solicitor List
                          </div>
                        </button>
                      </div>
                    </div>
                    Or click here for a&nbsp;
                    <a
                      href="#"
                      onClick={(e) => {
                        setOrderTypeId(3);
                        handleTextAreaChange(vanityName);
                      }}
                      className=" text-button_primary"
                    >
                      <u>Refresh of data</u>
                    </a>
                  </div>
                )}

                {orderTypeId == 3 && (
                  <div className="flex flex-col pt-3 font-sofia-pro w-full items-center justify-center">
                    <div className="w-[60%] sm:w-full md:w-[90%] pt-4">
                      <label className="block pb-1 pt-2  pl-1 font-sofia-pro text-sm font-medium text-normal">
                        List the LinkedIn vanitynames you want to be refreshed.
                        Please place each vanityname on it's own line.
                      </label>
                      <textarea
                        value={vanityName}
                        onChange={(e) => handleTextAreaChange(e.target.value)}
                        rows={10}
                        cols={40}
                        placeholder="Vanitynames list e.g. johndo"
                        className="w-full"
                      ></textarea>
                      <br />
                      <div className="w-full pt-5">
                        <div className="flex text-normal">
                          <div className="w-[50%] items-end">Rows:</div>
                          <div className="w-[50%] items-start">
                            {ownNumberRows.toLocaleString("en-UK")}
                          </div>
                        </div>
                        {/* <div className="flex items-center justify-center mt-2">
                          <div className="w-[50%] text-normal">
                            Total price:
                          </div>
                          <div className="w-[50%] text-normal">
                            {totalCost == 100 ? (
                              <span>minimum order €100</span>
                            ) : (
                              <>
                                €
                                {totalCost.toLocaleString("en-UK", {
                                  maximumFractionDigits: currencyFractionDigits,
                                })}
                              </>
                            )}
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="w-[250px]">
                      <div className="flex justify-between pt-7 gap-4 mb-4">
                        <button
                          onClick={() => setIsModalOpen(true)}
                          disabled={ownNumberRows === 0}
                          className="flex flex-row bg-button_primary  disabled:text-gray-500 self-stretch rounded-[40px] h-[48px] w-full text-white box-border border-button_primary items-center justify-center gap-[10px] cursor-pointer"
                        >
                          <div className="relative font-semibold">
                            Order Solicitor List
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col text-base pt-4 h-[60px] sm:h-[160px] bg-white/80 items-center justify-center text-center font-semibold text-normal w-full box-border ">
              <div className="flex flex-row flex-wrap w-[90%] items-center justify-center gap-3 box-border">
                <div
                  className="text-normal underline cursor-pointer"
                  onClick={() => (window.location.href = "https://eltic.io")}
                >
                  Home
                </div>
                <div>
                  <a href={`mailto:${userEmail}`} className="text-normal ">
                    {userEmail}
                  </a>
                </div>

                <div
                  className="text-normal underline cursor-pointer"
                  onClick={() => navigate("/privacypolicy")}
                >
                  Privacy
                </div>
                <div
                  className="text-normal underline cursor-pointer"
                  onClick={() => navigate("/termsandconditions")}
                >
                  T&C
                </div>
                <div className="text-normal">
                  &copy; 2023 - All Rights Reserved ELTic Ltd
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onEmailSubmit={handleEmailSubmit}
      />
      <Toaster />
    </>
  );
};

export default Home;
